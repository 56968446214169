import styled from 'styled-components'
import fontLogo from '../../fonts/moon_get.ttf'

// Main Section
export const MintSection = styled.div`

    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 50px;
    margin-bottom: 100px;

`

// Container (For proper width)
export const MintContainer = styled.div`

    width: 100%;
    height: auto;
    max-width: 1720px;
    padding: 0 24px;

    display: flex;
    justify-content: space-between;


`

// Examples
export const LeftContainer = styled.div`

    display: flex;
    flex-wrap: wrap;
    width: 35%;
    max-width: 45%;
    margin-right: 20px;
    align-items: flex-start;

    img {
        display: flex;
        justify-content: space-between;
        flex-basis: calc(50%);
        justify-content: center;
        flex-direction: column;

        max-width: 50%;
        height: auto;
        border-radius: 25px;
        position: static;
        transition: 0.2s all ease-in-out;

        &:hover {
            border-radius: 200px;
            transition: 0.2s all ease-in-out;
        }


    }

    .img-1 {
        padding-right: 5px;
        padding-bottom: 5px;
    }

    .img-2 {
        padding-left: 5px;
        padding-bottom: 5px;
    }

    .img-3 {
        padding-right: 5px;
        padding-top: 5px;
    }

    .img-4 {
        padding-left: 5px;
        padding-top: 5px;
    }

    @media (max-width: 1000px) {
        display: none;
    }

`

// Minting Dapp
export const RightContainer = styled.div`

    width: 75%;
    height: auto;
    padding: 20px 100px;
    max-width: 100%;
    border-radius: 25px;
    background-color: #f9f9f9;
    text-overflow: ellipsis;
    transition: 0.2s all ease-in-out;

    .loading_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: 0.2s all ease-in-out;
        gap: 20px;
        color: #ccc;

        img {
            opacity: 15%;
        }

    }

    @media (max-width: 1000px) {
        width: 100%;
        padding: 40px 40px;
    }

    p {
        font-size: 1.2rem;
        line-height: 30px;
        margin-top: 25px;
    }

    .header {
        font-weight: bold;
        font-size: 1.2rem;
        background-color: #3f1035;
        padding: 10px;
        color: white;
        border-radius: 15px;
        opacity: 100%;
        cursor: pointer;
        
        transition: 0.2s all ease-in-out;
        &:hover {
            opacity: 75%;
            transition: 0.2s all ease-in-out;
        }
    }

    .muted {
        opacity: 25%;
        font-size: 0.9rem;
        padding: 5px;
        margin: 5px 0;
    }

    .wallet_address {
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.2s all ease-in-out;
        &:hover {opacity: 100%; transition: 0.2s all ease-in-out;}
    }

    .info_section {

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        flex-wrap: wrap;

        .focus_box {
        width: 49.5%;
        padding: 20px;
        margin-bottom: 10px;
        background-color: #e3e3e3;
        border-radius: 15px;

        display: flex;
        justify-content: space-between;
        font-weight: bolder;
        
        .left {
            justify-self: flex-start;
        }

        .right {
            justify-self: flex-end;
        }

        @media (max-width: 760px) {
            width: 100%;
        }

        }
    }

    .counter_section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        
        .left {
            width: 5.5%;
            min-width: 50px;
            border-radius: 25%;
            height: 50px;
            background-color: #3f1035;
            margin-right: 2%;
            cursor: pointer;

            display: flex;
            justify-content: center;
            padding-top: 3px;
            font-weight: bold;
            color: white;
            font-size: 2rem;

            transition: 0.2s all ease-in-out;
            &:hover {
                background-color: #6f3f65;
                border-radius: 50%;
                transition: 0.2s all ease-in-out;
            }
            
        }

        .middle {
            height: 50px;
            border: 2px;
            border-radius: 10px;
            border-color: #3f1035;
            border-style: solid;
            flex-grow: 2;
            color: #3f1035;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
        }

        .right {
            width: 5.5%;
            min-width: 50px;
            border-radius: 25%;
            height: 50px;
            background-color: #3f1035;
            margin-left: 2%;
            cursor: pointer;
            color: white;
            display: flex;
            justify-content: center;
            align-content: center;
            font-weight: bold;
            font-size: 2rem;
            padding-top: 6px;
            padding-left: 2px;
            
            transition: 0.2s all ease-in-out;
            &:hover {
                background-color: #6f3f65;
                border-radius: 50%;
                transition: 0.2s all ease-in-out;
            }
        }

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    }

`

export const ActionBtn = styled.button`

    border-radius: 15px;
    border: 0px;
    background-color: #3f1035;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    width: 100%;
    height: 70px;
    font-family: 'Moon Get';
    font-size: 1.2rem;
    letter-spacing: 4px;
    font-weight: bold;
    cursor: pointer;

    @font-face {
        font-family: Moon Get;
        src: url(${fontLogo});
    }

    transition: 0.2s all ease-in-out;
            &:hover {
                background-color: #6f3f65;
                border-radius: 25px;
                transition: 0.2s all ease-in-out;
    }

    @media (max-width: 760px) {
            font-size: 0.9rem;
    }

`