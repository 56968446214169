import React, {useState} from 'react';
import { MintSection, MintContainer, LeftContainer, RightContainer, ActionBtn} from './MintingElements';
import { injected } from '../Wallet/Connectors'
import { useWeb3React } from '@web3-react/core'
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";

import LoadingIcons from 'react-loading-icons'
import {Link, animateScroll as scroll} from 'react-scroll'

import donut1 from '../../images/marquee/1.png'
import donut2 from '../../images/marquee/22.png'
import donut3 from '../../images/marquee/36.png'
import donut4 from '../../images/marquee/50.png'
import donutOutline from '../../images/img_donut_outline.png'

const MintingSection = () => {

  return (
      <>
      
      <MintSection id="mintSection">
          <MintContainer>
            
            {/* NFT GALLARY */}
            <LeftContainer>
                <img src={donut1} draggable="false" class="img-1"></img>
                <img src={donut2} draggable="false" class="img-2"></img>
                <img src={donut3} draggable="false" class="img-3"></img>
                <img src={donut4} draggable="false" class="img-4"></img>
            </LeftContainer>

            {/* MINTING DAP */}
            <RightContainer>
                <div class="loading_container">
                    <img src={donutOutline}></img>
                    <LoadingIcons.Hearts fill="#cfcfcf"></LoadingIcons.Hearts> 
                    <p><center>Non Fungible Treats will be open for business soon!
                    <br/>
                    For now, follow our social media to keep track of sweet and delicious updates!</center></p>
                </div>
            </RightContainer>

          </MintContainer>
      </MintSection>
      
      </>
  );
};

export default MintingSection;
