import styled from 'styled-components'

import fontLogo from '../../fonts/moon_get.ttf'

export const HeroContainer = styled.div`

    width: 100%;
    height: auto;
    padding-bottom: 100px;
    background-color: #ffccf5;
    position: relative;
    overflow: hidden;

`

export const WaveTop = styled.img`
    z-index: 0;
    width: 90%;
    max-width: 1720px;
    min-width: 800px;
    height: auto;
    position: absolute;

    right: -20rem;

`

export const WaveBottom = styled.img`

    z-index: 0;
    width: 90%;
    max-width: 1720px;
    min-width: 800px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: -5rem;

`

export const Hero = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;

`

export const HeroContent = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1720px;
    margin-top: 50px;
`

export const HeroImg = styled.img`
    max-width: 47%;
    justify-self: flex-start;
    align-items: center;

    @media (max-width: 767px) {
        display: none;
    }

`

export const HeroRight = styled.div`
    justify-self: flex-end;
    width: 100%;
    padding: 0 24px;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

`

export const HeroLogo = styled.img`
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
        
        position: static;
        

        @media (max-width: 767px) {
            margin-bottom: 50px;
            margin-left: 20px;
        }

        
`

export const HeroMintBtn = styled.button`

    width: 250px;
    height: 70px;
    background-color: #3f1035;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Moon Get';
    letter-spacing: 4px;
    font-size: 1.2rem;
    opacity: 75%;
    border: 0;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        opacity: 100%;
        width: 300px;
        transition: 0.4s ease-in-out;
    }
    
    @font-face {
        font-family: Moon Get;
        src: url(${fontLogo});
        
    }

`
