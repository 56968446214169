import styled from "styled-components";

import fontLogo from '../../fonts/moon_get.ttf'

// DISPLAY CONTAINER
export const DisplayContainer = styled.div`

height: auto;
padding: 50px 0;

// DEBUG
margin-top: 25px;

`

export const DisplayHeader = styled.img`

    max-width: 15%;
    min-width: 250px;
    display:block;
    margin: auto;

    image-rendering: pixelated;

` 

// MARQUEE

export const DisplayCase = styled.div`
    margin-top: 75px;
    overflow: hidden;
`

export const DisplayMarquee = styled.div`

    width: 100%;
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;

    animation: scroll 120s linear infinite;

    @media (max-width: 767px) {
        animation: scroll 40s linear infinite;
        
    }


    @keyframes scroll {
        0% {transform: translateX(0%);}
        60% {transform: translateX(-60%);}
    }

`

export const DisplayCaseImg = styled.img`

    max-width: 400px;
    width: 20%;
    min-width: 200px;
    display: block;

    transition: 0.2s all ease-in-out;
    &:hover {
        border-radius: 50%;
        transition: 0.2s all ease-in-out;
    }


`
