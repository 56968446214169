import React from 'react';
import Navbar from '../Navbar';

import {HeroContainer, WaveTop, WaveBottom,
        HeroContent, HeroImg, Hero, HeroRight,
        HeroLogo, HeroMintBtn} from './HeroSectionElements'

import imgWaveTop from '../../images/img_hero_wave_top.png';
import imgWaveBottom from '../../images/img_hero_wave_bottom.png';

import {Link, animateScroll as scroll} from 'react-scroll'

import imgHero from '../../images/img_hero_donut.png';
import imgHeroLogo from '../../images/img_hero_logo.png';

const HeroSection = () => {
  return (

    <HeroContainer>
      <WaveTop src={imgWaveTop}/>
      <WaveBottom src={imgWaveBottom}/>
      <Navbar></Navbar>

      <Hero>
        <HeroContent>
          <HeroImg src={imgHero}></HeroImg>
          <HeroRight>
            <HeroLogo src={imgHeroLogo} draggable="false"></HeroLogo>

            <Link 
            to="mintSection"
            smooth={true}
            offset={-300}
            duration={750}
            
            ><HeroMintBtn>MINT</HeroMintBtn></Link>

          </HeroRight>
        </HeroContent>
      </Hero>

    </HeroContainer>
  );
};

export default HeroSection;
