import styled from 'styled-components'

export const FooterSection = styled.div`
    background-color: #3f1035;
    height: 120px;
    
    display: flex;
    justify-content: center;

    color: white;
`

export const FooterContainer = styled.div`
    width: 1720px;

    display: flex;
    justify-content: center;
    align-items: center;

`

export const FooterContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`

export const FooterText = styled.div`
    text-align: center;
    opacity: 25%;
    font-weight: 600;
`

export const FooterIcons = styled.div`

    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    
    img {
        width: 100%;
        opacity: 25%;
        
        transition: 0.2s all ease-in-out;
        &:hover {
            opacity: 100%;
            transition: 0.2s all ease-in-out;
        }
        cursor: pointer;
    }

    a {
        width: 40px;
    }
`