import styled from 'styled-components'
import fontLogo from '../../fonts/moon_get.ttf'

export const Nav = styled.nav`
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1720px;

`;

export const NavLogo = styled.a`
    color: #3f1035;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Moon Get';
    letter-spacing: 2px;
    opacity: 75%;
    transition: 0.2s ease-in-out;

    &:hover {
        opacity: 100%;
        transition: 0.2s ease-in-out;
    }
    
    @font-face {
        font-family: Moon Get;
        src: url(${fontLogo});
        
    }

`;

export const NavSocial = styled.div`
    display: flex;
    align-items: center;
`;

export const NavSocialBtn = styled.img`
    width: 35px;
    height: 35px;
    margin-left: 15px;
    position: sticky;
    opacity: 75%;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        opacity: 100%;
        transition: 0.2s ease-in-out;
    }
    
`;

export const NavLink = styled.a`
    display: flex;
    align-items: center;
`

