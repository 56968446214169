import React from 'react';
import {Nav, NavbarContainer, NavLogo, NavSocial,
     NavSocialBtn, NavLink} from './NavbarElements';

import imgTwitter from '../../images/img_nav_twitter.png';
import imgOpensea from '../../images/img_nav_opensea.png';
import imgDiscord from '../../images/img_nav_discord.png';

const Navbar = () => {
  return (
      <>
      <Nav>
          <NavbarContainer>
              <NavLogo href="#">NFTREATS</NavLogo>
              <NavSocial>
                  <NavLink href="#coming_soon">
                    <NavSocialBtn src={imgOpensea} draggable="false"></NavSocialBtn>
                  </NavLink>
                  <NavLink href="https://twitter.com/0xNFTreats">
                      <NavSocialBtn src={imgTwitter} draggable="false"></NavSocialBtn>
                  </NavLink>
                  <NavLink href="#coming_soon">
                    <NavSocialBtn src={imgDiscord} draggable="false"></NavSocialBtn>
                  </NavLink>
              </NavSocial>
          </NavbarContainer>
      </Nav>

      </>
  );
};

export default Navbar;

