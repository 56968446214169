import './App.css';
import DisplaySection from './components/DisplaySection';
import HeroSection from './components/HeroSection';
import MintingSection from './components/MintingSection';
import DiagramSection from './components/DiagramSection';
import Footer from './components/FooterSection';

import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'


function getLibrary(provider) {
  return new Web3(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div>
      <HeroSection />
      <DisplaySection />
      <MintingSection />
      <DiagramSection />
      <Footer />
    </div>
    </Web3ReactProvider>
  );
}

export default App;
