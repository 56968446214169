import React from 'react';
import { FooterContainer, FooterSection, FooterIcons, FooterText, FooterContent} from './FooterElements';

import imgTwitter from '../../images/img_footer_twitter.png'
import imgOpensea from '../../images/img_footer_opensea.png'
import imgDiscord from '../../images/img_footer_discord.png'

const Footer = () => {
  return (
      <>
      <FooterSection>

        <FooterContainer>

            <FooterContent>
                <FooterIcons>
                  <a><img src={imgOpensea}></img></a>
                  <a href="http://twitter.com/0xNFTreats"><img src={imgTwitter}></img></a>
                  <a><img src={imgDiscord}></img></a>
                </FooterIcons>
                <FooterText>
                    2022 &copy; Non Fungible Treats
                </FooterText>
            </FooterContent>

        </FooterContainer>

      </FooterSection>
      </>
  )
};

export default Footer;
