import React from 'react';
import { DisplayContainer, DisplayCase, DisplayCaseImg, DisplayMarquee, DisplayHeader} from './DisplayElements';

import donut1 from '../../images/marquee/1.png'
import donut2 from '../../images/marquee/2.png'
import donut3 from '../../images/marquee/3.png'
import donut4 from '../../images/marquee/4.png'
import donut5 from '../../images/marquee/5.png'
import donut6 from '../../images/marquee/6.png'
import donut7 from '../../images/marquee/7.png'
import donut8 from '../../images/marquee/8.png'
import donut9 from '../../images/marquee/9.png'
import donut10 from '../../images/marquee/10.png'
import donut11 from '../../images/marquee/11.png'
import donut12 from '../../images/marquee/12.png'
import donut13 from '../../images/marquee/13.png'
import donut14 from '../../images/marquee/14.png'
import donut15 from '../../images/marquee/15.png'
import donut16 from '../../images/marquee/16.png'
import donut17 from '../../images/marquee/17.png'
import donut18 from '../../images/marquee/18.png'
import donut19 from '../../images/marquee/19.png'
import donut20 from '../../images/marquee/20.png'
import donut21 from '../../images/marquee/21.png'
import donut22 from '../../images/marquee/22.png'
import donut23 from '../../images/marquee/23.png'
import donut24 from '../../images/marquee/24.png'
import donut25 from '../../images/marquee/25.png'
import donut26 from '../../images/marquee/26.png'
import donut27 from '../../images/marquee/27.png'
import donut28 from '../../images/marquee/28.png'
import donut29 from '../../images/marquee/29.png'
import donut30 from '../../images/marquee/30.png'
import donut31 from '../../images/marquee/31.png'
import donut32 from '../../images/marquee/32.png'
import donut33 from '../../images/marquee/33.png'
import donut34 from '../../images/marquee/34.png'
import donut35 from '../../images/marquee/35.png'
import donut36 from '../../images/marquee/36.png'
import donut37 from '../../images/marquee/37.png'
import donut38 from '../../images/marquee/38.png'
import donut39 from '../../images/marquee/39.png'
import donut40 from '../../images/marquee/40.png'
import donut41 from '../../images/marquee/41.png'
import donut42 from '../../images/marquee/42.png'
import donut43 from '../../images/marquee/43.png'
import donut44 from '../../images/marquee/44.png'
import donut45 from '../../images/marquee/45.png'
import donut46 from '../../images/marquee/46.png'
import donut47 from '../../images/marquee/47.png'
import donut48 from '../../images/marquee/48.png'
import donut49 from '../../images/marquee/49.png'
import donut50 from '../../images/marquee/50.png'


import imgDisplayHeader from '../../images/marquee/img_header_the.png'

const DisplaySection = () => {
  return (
    <>

    <DisplayContainer>
            <DisplayHeader src={imgDisplayHeader} draggable="false"></DisplayHeader>
    <DisplayCase>
        <DisplayMarquee>
            <DisplayCaseImg src={donut1} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut2} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut3} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut4} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut5} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut6} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut7} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut8} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut9} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut10} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut11} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut12} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut13} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut14} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut15} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut16} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut17} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut18} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut19} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut20} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut21} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut22} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut23} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut24} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut25} draggable="false"></DisplayCaseImg>
        </DisplayMarquee>

        <DisplayMarquee>
            <DisplayCaseImg src={donut43} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut44} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut45} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut46} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut47} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut48} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut49} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut50} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut26} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut27} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut28} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut29} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut30} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut31} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut32} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut33} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut34} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut35} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut36} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut37} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut38} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut39} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut40} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut41} draggable="false"></DisplayCaseImg>
            <DisplayCaseImg src={donut42} draggable="false"></DisplayCaseImg>
            
        </DisplayMarquee>
    </DisplayCase>

    </DisplayContainer>

    </>
    );
};
  


export default DisplaySection;
