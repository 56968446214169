import React from 'react';
import {DiagramSec, DiagramContainer, DiagramInfo, DiagramImage, DiagramHeaderImage, DiagramInfoText} from '../DiagramSection/DiagramElements'

import imgDiagram from '../../images/img_diagram_alt.png'
import imgDiagramHeader from '../../images/img_secret_formula.png'

const DiagramSection = () => {
  return (
      <>
      <DiagramSec>
          <DiagramContainer>
            <DiagramImage><img src={imgDiagram} draggable="false"></img></DiagramImage>
            
            {/* DIAGRAM INFO TEXT BOX */}
            <DiagramInfo>

                <DiagramHeaderImage><img src={imgDiagramHeader} draggable="false"></img></DiagramHeaderImage>
                <DiagramInfoText><b>Non Fungible Treats </b>
                are baked to perfection using a handful of unique properties. From tasty frosting and toppings, to symbolic charms and glaze!
                There is a lot that goes into making one of our sweet and delicious treats!</DiagramInfoText>

            </DiagramInfo>
              
          </DiagramContainer>
      </DiagramSec>
      </>
  )
};

export default DiagramSection;
