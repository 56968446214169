import styled from 'styled-components'
import fontLogo from '../../fonts/moon_get.ttf'

// COLORS

export const DiagramSec = styled.div`
    background-color: #ffeedd;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
`

export const DiagramContainer = styled.div`

    width: 100%;
    max-width: 1720px;
    display: flex;
    flex-direction: row;
    align-content: space-between;

    padding: 80px 24px;
    gap: 50px;
    
    @media (max-width: 1250px) {
        flex-direction: column;
        padding: 48px 24px;
    }

`

export const DiagramImage = styled.div`

    align-items: center;
    display: flex;
    width: 60%;

    img {
        max-width: 100%;
    }

    @media (max-width: 1250px) {
        justify-content: center;
        width: 100%;
    }
    
`

export const DiagramInfo = styled.div`
    background-color: #eed5bd;
    border-radius: 20px;
    
    width: 40%;
    padding: 48px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    
    @media (max-width: 1250px) {
        width: 100%;
        margin-top: 48px;
    }


`

export const DiagramInfoText = styled.div`
    font-size: 1.2rem;
    line-height: 30px;
    text-align: justify;

`

export const DiagramHeaderImage = styled.div`

    display: flex;
    justify-content: center;
    width: 100%;
    img {
        max-width: 60%;
        @media (max-width: 1250px) {
        max-width: 75%;
    }
    }

`